import { notify } from '@sobrus-com/sobrus-design-system-v2';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import Cookies from 'js-cookie';
import { apiUrl } from '../env';

// eslint-disable-next-line import/extensions

const AXIOSAUTH = axios.create({
	baseURL: apiUrl,
	responseType: 'json',
	withCredentials: true,
});

AXIOSAUTH.interceptors.request.use(config => {
	const token = Cookies.get('csrfToken');

	if (token) {
		if (config.method !== 'get') config.headers['x-csrf-token'] = token; // Ajouter le token CSRF dans les en-têtes de la requête
	} else {
		localStorage.clear();
		window.location.href = '/login';
		Cookies.remove('csrfToken');
	}
	return config;
});

export { AXIOSAUTH };

const AXIOS = axios.create({
	baseURL: apiUrl,
	responseType: 'json',
	withCredentials: true,
});

AXIOS.interceptors.request.use(config => {
	const token = Cookies.get('csrfToken');

	if (token) {
		if (config.method !== 'get') config.headers['x-csrf-token'] = token; // Ajouter le token CSRF dans les en-têtes de la requête
	} else {
		localStorage.clear();
		window.location.href = '/login';
		Cookies.remove('csrfToken');
	}
	return config;
});

AXIOS.interceptors.response.use(
	response => {
		return response;
	},
	async function (error) {
		// if (error?.response?.status === 401) {
		// 	// localStorage.clear();
		// 	// window.location.href = '/login';
		// 	// Cookies.remove('csrfToken');
		// 	notify({
		// 		type: 'danger',
		// 		msg: "Vous n'êtes pas autorisé",
		// 		delay: 5000,
		// 	});
		// 	window.location.href = '/denied_access';
		// }
		// else if (error?.message === 'canceled') {
		// 	return;
		// }
		// else if (error?.response?.status === 401) {
		// 	// window.location.href = '/error';
		// 	notify({
		// 		type: 'danger',
		// 		msg: "Vous n'êtes pas autorisé",
		// 		delay: 5000,
		// 	});
		// }
		// else {
		let errorMessage = 'Erreur Serveur Veuiller contacter un administrateur';
		if (error?.response?.data?.message) {
			if (typeof error.response.data.message === 'string') {
				errorMessage =
					error.response.data.message ===
						'validityStartDate must be less or equal than validityEndDate' ||
					error.response.data.message ===
						'endDate must be greater than startDate'
						? 'Veuillez choisir une date de fin supérieur à la date de début et réessayez.'
						: error.response.data.message;
			}
			// else if (typeof error.response.data.message === 'object') {
			// 	errorMessage = JSON.stringify(error.response.data.message);
			// }
		} else {
			errorMessage = 'Erreur Serveur Veuiller contacter un administrateur';
		}
		//THIS IS FOR GLOVO ERRORS
		if (error?.response?.data?.error) {
			const errorMessageInData = error.response.data.error;
			// console.log('errorMessageInData', error.response.data.error);

			const errorMessageOne = 'Some of';
			const errorMessageTwo = 'Order with';
			const errorMessageThree = 'Modified order';
			const errorMessageFour =
				'Modify quantity. This order already contains a product';

			const translations: { [key: string]: string } = {
				'An invalid request in which there are no products listed for replacement, removal, or addition.':
					'Aucun produit ajouté, veuillez réessayer.',
				'Order is already picked or cancelled and so cannot be modified.':
					'La commande est déjà récupérée ou annulée et ne peut donc pas être modifiée.',
				'Order is a big order and so is automatically split, so cannot be updated.':
					'Impossible de modifier les produits des commandes fractionnées automatiquement.',
				'The newly modified order that the request makes exceeds the maximum size permitted in proportion to the original order. This cannot be twice the size of the original order, although all modified orders smaller or equal to 5 are permitted.':
					'La quantité de la commande modifiée dépasse le maximum autorisé.',
				'The order modification lists contain a product ID that does not exist.':
					"L'ID du produit acheté est non présent dans la commande d'origine.",
				'Product not found in this order':
					'Produit non trouvé dans cette commande.',
			};

			if (errorMessageInData.includes(errorMessageOne)) {
				errorMessage =
					'Produit indisponible sur Glovo, veuillez refaire la synchronisation et réessayer.';
			} else if (errorMessageInData.includes(errorMessageTwo)) {
				errorMessage = 'Cette commande a été déjà modifié.';
			} else if (errorMessageInData.includes(errorMessageThree)) {
				errorMessage =
					'La quantité de commande modifiée dépasse le maximum autorisé.';
			} else if (errorMessageInData.includes(errorMessageFour)) {
				errorMessage =
					'Le produit existe déjà sur la liste, veuillez modifier que la quantité.';
			}

			errorMessage = translations[errorMessageInData] || errorMessageInData;
		}

		notify({
			type: 'danger',
			msg: errorMessage,
			delay: 5000,
		});
		// }
		throw error;
	}
);

// for repeat the request 3 time
axiosRetry(AXIOS, { retryDelay: axiosRetry.exponentialDelay });
axiosRetry(AXIOS, { retries: 3 });

export default AXIOS;
